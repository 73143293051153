<template>
  <div v-if="sortedProjects" :key="currentYear" class="container" style="max-width: 75vw;">
    <div class="row header">
      <div class="cell client void"></div>
      <div v-for="(month, index) in months" :key="index" class="cell month">
        <span class="label" style="display: block;">{{ month }}</span>
      </div>
    </div>

      <!-- Data rows for clients -->
    <div class="data-rows">
      <div v-for="project in sortedProjects" :key="project.id" class="row">
        <div class="cells">
          <div class="cell client">
            <div class="client__label" > {{ project.client }} <br> {{project.data_year}} : {{project.product}}</div>
          </div>
          <div v-for="monthIndex in months.length" :key="monthIndex" class="cell content">
            <div v-for="i in getMonthSubcells(monthIndex)" :key="`${monthIndex}-${i}`" class="subcell" :style="{ width: buildSubcellWidth(monthIndex)}" :class="{ last: i === getDaysInMonth(monthIndex, currentYear)-1 }"/>
          </div>
        </div>
        <div class="box constraint" :style="{ width: getWidthConstraint(project.id), marginLeft: getMarginLeftConstraint(project.id) }"  v-tooltip="`${formatDate(getReceivedDate(project.id))} <--> ${formatDate(getDueDate(project.id))}`"/>
        <div class="today-line" :style="{ marginLeft: getMarginLeft()}"></div>
        <div
          :key="project.id"
          class="box"
          :class="{
            [project.dispatch]: project.dispatch,
            'no-dispatch': !project.dispatch, 
            [project.status]: project.status === 'todo' || project.status === 'done'
          }"
          :style="{ width: getWidth({start: getStartDate(project.id), end: getEstimatedDeliveryDate(project.id)}), marginLeft: getMarginLeft(getStartDate(project.id)) }"
          v-tooltip="{ content: `<b>${formatDate(getStartDate(project.id))}</b> - <b>${formatDate(getEstimatedDeliveryDate(project.id))}</b>`, html: true }"
        />
      </div>
    </div>
  </div>
  <div v-else> aucune donnée disponible </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import _ from 'lodash'
import useProdProjectStore from '@/stores/prod-project-store'
import { getProductionData } from '@/commons/lib/apiClient.js'
import { storeToRefs } from 'pinia'

const projects = ref([])
const prodProjectStore = useProdProjectStore()
const { showOptions, currentYear, features } = storeToRefs(prodProjectStore)
const months = ref([
  'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun',
  'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'
])

const graphWidthExpression = '100% - 352px'

const isShowDoneActive = computed(() => {
  return showOptions.value.includes('done');
})

const filteredProjects = computed(() => {
  return isShowDoneActive.value
    ? (projects.value ?? [])
    : (projects.value ?? []).filter(project => project.status !== 'done')
})

const statusOrder = { "in progress": 1, "todo": 2, "done": 3 };

const sortedProjects = computed(() => {
  return (filteredProjects.value ?? [])
    .slice()
    .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
});

onMounted(async () => {
  const data = await getProductionData('get_inventory_order_json_result', currentYear.value)
  if (!data) return
  projects.value = data
})

function formatDate(date) {
  if (!date) {
    return "N/A";
  }
  if (date.includes("T")) {
    return date.split("T")[0];
  }
  return date;
}

function getDaysInMonth (monthIndex, year) {
  const month = monthIndex + 1
  const lastDay = new Date(year, month, 0).getDate()
  return lastDay
}

function getMonthSubcells (monthIndex) {
  const days = getDaysInMonth(monthIndex, currentYear.value)
  return [...Array(days).keys()]
}

function buildSubcellWidth (monthIndex) {
  const width = 100 / getDaysInMonth(monthIndex, currentYear.value)
  return `${width.toString()}%`
}

function getDurationInSeconds (dateStart, dateEnd) {
  const dateEndDate = dateEnd ? new Date(dateEnd) : new Date()
  const dateStartDate = dateStart ? new Date(dateStart) : new Date()
  const duration = Math.abs((dateEndDate - dateStartDate)) / 1000
  return duration
}

const yearInSeconds = 60 * 60 * 24 * 365

const itemStart = start => {
  const elapsedTime = getDurationInSeconds(`${currentYear.value.toString()}-01-01`, start)
  const position = elapsedTime / yearInSeconds
  return position
}

const itemLength = item => {
  const lengthTime = getDurationInSeconds(item.start, item.end)
  const lengthPart = lengthTime / yearInSeconds
  return lengthPart
}

const getWidth = item => {
  const adjustedStart = item.start && item.start.substring(0, 4) < currentYear.value ? `${currentYear.value}-01-01` : item.start;
  return `calc(${itemLength({ ...item, start: adjustedStart })} * (${graphWidthExpression}))`;
}

const getMarginLeft = startDate => {
  const adjustedStart = startDate && startDate.substring(0, 4) < currentYear.value ? `${currentYear.value}-01-01` : startDate;
  const itemStartPosition = itemStart(adjustedStart)
  return `calc(${itemStartPosition} * (${graphWidthExpression}))`
}

function getReceivedDate (projectId) {
  const feature = features.value.find(f => f.id === +projectId)
  return feature?.order_received_date ?? `${currentYear.value}-01-01`
}

function getDueDate (projectId) {
  const feature = features.value.find(f => f.id === +projectId)
  return feature?.due_date ?? `${currentYear.value}-12-31`
}

function getStartDate (projectId) {
  const feature = features.value.find(f => f.id === +projectId);
  if (feature?.start_date === null && feature?.status === 'in progress') {
    return `${currentYear.value}-01-01`;
  }
  return feature?.start_date ?? null;
}

function getEstimatedDeliveryDate (projectId) {
  const feature = features.value.find(f => f.id === +projectId);
  if (feature?.delivery_moment) {
    return feature?.delivery_moment
  }
  if (feature?.estimated_delivery_date === null && feature?.status === 'in progress') {
    return `${currentYear.value}-12-31`;
  }
  return feature?.estimated_delivery_date ?? null;
}

function getWidthConstraint (projectId) {
  const receivedProject = getReceivedDate(projectId)
  const dueProject = getDueDate(projectId)
  const myItem = { start: receivedProject, end: dueProject }
  return getWidth(myItem)
}

function getMarginLeftConstraint (projectId) {
  const receivedProject = getReceivedDate(projectId)
  return getMarginLeft(receivedProject)
}

</script>

<style lang="scss" scoped>
.box {
    position: absolute;
    top: 0px;
    left: 352px;
    width: 40px;
    border-radius: 1px;
    height: 10px;
    cursor: pointer;
    &.JAK {
      top: 20px;
      background-color: #325F91;
    }
    &.FM {
      top: 20px;
      background-color: #325F91;
      background-image: repeating-linear-gradient(
        45deg,
        #444444 0px,
        #444444 10px,
        transparent 10px,
        transparent 20px
      );
    }
    &.no-dispatch {
      top: 20px;
      background-color: #909132;
    }
    &.done {
      top: 20px;
      background-color: #4B905B;
    }
    &.constraint {
      top: 15px;
      height: 20px;
      background-color: #f39dcc;
      opacity: 0.25;
    }
  }
.container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin: 12px;
  .today-line {
    position: absolute;
    top: 0px;
    left: 352px;
    width: 2px;
    height: 100%;
    background: repeating-linear-gradient(
      to bottom,
      grey,
      grey 5px,
      white 5px,
      white 10px
    );
  }
  .row {
    position: relative;
    height: 50px;
  }
  .row.header, .cells {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .cell {
      display: flex;
      // flex-direction: column;
      flex: 1;
      height: 50px;
      text-align: center;
      align-items: center;
      position: relative;
      justify-content: center;
        &.client {
          display: block;
          text-align: left;
          min-width: 350px;
          font-size: 14px;
          background-color: white;
          border: 1px solid lightgray;
          &.void {
            border: none;
            background-color: transparent;
          }
          i {
            position: absolute;
            top: 6px;
            right: 6px;
            color:lightgray;
          }
          .client__label {
            cursor: pointer;
            padding-left: 8px;
            padding-top: 4px;
          }
        }
        &.content {
          background-color: white;
          border: 1px solid lightgray;
        }
        &.month {
          border: 1px lightgray solid;
          background-color: white;
          border: 1px solid lightgray;
          border-right-style: dashed;
          border-left-style: dashed;
          text-transform: uppercase;
        }
        .subcell {
          border-right: 1px solid #d3d3d357;
          height: 100%;
          opacity: 70%;
          &.last {
            border-right: 1px solid purple;
          }
        }
    }
  }
}
</style>
