import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { getProductionData } from '@/commons/lib/apiClient.js'

async function getData(year) {
    const response = await getProductionData('get_inventory_order_json_result', year)
    return response
}

const useProdProjectStore = defineStore('prod-project', () => {
    const showOptions = ref([])
    const currentYear = ref(new Date().getFullYear())
    const features = ref([])
    const populateData = async () => {
        features.value = await getData(currentYear.value)
    }

    return {
        showOptions,
        currentYear,
        features,
        populateData,
    }
})

export default useProdProjectStore
