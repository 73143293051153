<template>
  <div class="table-container">
    <check-list v-model="showOptions" :items="[{id: 'done'}]" >
      <template #left>
        <span class="item-label"> Montrer les projets terminés. </span>
      </template>
    </check-list>
  </div>
  <prodGanttChart style="width: 100%;" />
</template>

<script setup>
import prodGanttChart from '../prod-gantt-chart.vue'
import { CheckList } from '@jakarto3d/jakui'
import useProdProjectStore from '@/stores/prod-project-store'
import { storeToRefs } from 'pinia'


const prodProjectStore = useProdProjectStore();
const { showOptions } = storeToRefs(prodProjectStore);
</script>

<style scoped>

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px;
}
.item-label {
  padding-left: 15px;
}

</style>
