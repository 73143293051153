export default [
  {
    id: 'overview-thematic',
    title: 'Aperçu',
    component: 'overview-component',
    viewportStats: '',
    faicon: 'fa-map'
  },
  {
    id: 'stream-thematic',
    title: 'Planification',
    component: 'stream-component',
    viewportStats: '',
    faicon: 'fa-stream'
  },
  {
    id: 'production-board-thematic',
    title: 'Production',
    component: 'production-board-component',
    viewportStats: '',
    faicon: 'fa-users-cog'
  },
  // {
  //   id: 'planification-thematic',
  //   title: 'Gestion',
  //   component: 'data-viewer',
  //   viewportStats: '',
  //   faicon: 'fa-cog'
  // },
  {
    id: 'calculator-thematic',
    title: 'Kilométrage par ville',
    component: 'analysis-component',
    viewportStats: '',
    faicon: 'fa-calculator'
  }
]
